import React from "react"
import Footer from "../footer"

const Layout = ({children }) => {

  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

  return (
    <div id="page">
      {children}
      <Footer/>
    </div>
  )
}

export default Layout
