const SiteConfig = {
  siteTitle: "Nate Walters | Developer & Digital Designer", // Site title.
  siteTitleShort: "NB Walters", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "Nate Walters", // Alternative site title for SEO.
  maskableLogo: "content/assets/maskable_icon_x512.png", // Logo used for SEO and manifest.
  manifestThemeColor: "#333333", // Used for setting manifest and progress theme colors.
  manifestBackgroundColor: "#fafafa", // Used for setting manifest background color.
  siteUrl: "https://nbwalters.dev", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: "Nate Walters, Web Developer And Digital Designer.", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteRssTitle: "Nate Walters | NBWalters.dev", // Title of the RSS feed
  siteFBAppID: "NateWalters", // FB Application ID for using app insights
  googleAnalyticsID: "G-J3Y3FE5VRV", // GA tracking ID.
  disqusShortname: "nbwalters", // Disqus shortname.
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  postsPerPage: 1000, // Amount of posts displayed per listing page.
  userName: "Nate Walters", // Username to display in the author segment.
  userEmail: "newsproutsmedia@gmail.com", // Email used for RSS feed's author segment
  userTwitter: "", // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: "Sedro-Woolley, WA", // User location to display in the author segment.
  userAvatar: "profile-pic.jpg", // User avatar to display in the author segment.
  userDescription:
    "We have to continually be jumping off cliffs and developing our wings on the way down. – Kurt Vonnegut", // User description to display in the author segment.
  // Links to social profiles/projects you want to display in the author segment/navigation bar.
  userLinks: [
    {
      label: "GitHub",
      url: "https://github.com/newsproutsmedia/nbwaltersdev",
      iconClassName: "fa fa-github",
    }
  ],
  copyright: "Copyright © 2020. Advanced User", // Copyright string for the footer of the website and RSS feed.
  themeColors: {
    primary: "#FD91A6",
    secondary: "#F7F739",
    tertiary: "#1A1464",
    accent: "#F24D9F"
  },
  themeFonts: {
    primaryFont: "Oswald",
    secondaryFont: "Poppins",
    accentFont: "Montserrat"
  }
};

module.exports = SiteConfig;