import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import { FaLinkedin, FaGithub, FaMedium } from 'react-icons/fa';
import { OutboundLink } from "gatsby-plugin-google-gtag";

const Bio = ({accentColor, textColor}) => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 64, height: 64, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
        }
      }
    }
  `);

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author;

  const avatar = data?.avatar?.childImageSharp?.fixed;

  return (
  <article className="media">
    <figure className="media-left">
      <p className="image is-64x64">
        <Image
          fixed={avatar}
          alt={author?.name || ``}
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
      </p>
    </figure>
    <div className="media-content">
      <div className="content">
          <p className="is-size-5 mb-0" style={{color: textColor}}>{author.name}</p>
          <p className="is-family-secondary is-size-7" style={{textColor}}>{author?.summary || null}</p>
      </div>
      <nav className="level is-mobile">
        <div className="level-left">
          <OutboundLink href="https://www.linkedin.com/in/nbwalters/" className="level-item" aria-label="Nate Walters on LinkedIn">
            <span className="icon is-small"><FaLinkedin style={{color: accentColor}}/></span>
          </OutboundLink>
          <OutboundLink href="https://github.com/newsproutsmedia" className="level-item" aria-label="Nate Walters on GitHub">
            <span className="icon is-small"><FaGithub style={{color: accentColor}}/></span>
          </OutboundLink>
          <OutboundLink href="https://medium.com/@natewalters" className="level-item" aria-label="Nate Walters on Medium">
            <span className="icon is-small"><FaMedium style={{color: accentColor}}/></span>
          </OutboundLink>
        </div>
      </nav>
    </div>
  </article>
  )
}

export default Bio
