import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { FaAngleDoubleRight } from "react-icons/fa";
import Bio from "./blog/bio";
import SiteConfig from "../../data/SiteConfig";

const _ = require("lodash");

export default function Footer() {

  const data = useStaticQuery(graphql`
    query footerQuery {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              tags
              category
            }
          }
        }
      }
    }
  `)

  const postEdges = data.allMarkdownRemark.edges;
  let tagSet = new Set();
  let catSet = new Set();

  // Create blog posts pages
  // But only if there's at least one markdown file found at "content/blog" (defined in gatsby-config.js)
  // `context` is available in the template as a prop and as a variable in GraphQL
  postEdges.forEach((post) => {
    post.node.frontmatter.tags.forEach((tag) => {
      tagSet.add(tag);
    });
    post.node.frontmatter.category.forEach((cat) => {
      catSet.add(cat);
    });
  })

  let tagArray = Array.from(tagSet).sort();
  let catArray = Array.from(catSet).sort();

  return (

    <footer className="footer has-background-black has-text-white">
      <div className="container">
        <div className="columns is-variable is-1-mobile is-3-tablet is-3-desktop is-3-widescreen is-3-fullhd is-8">

          <div className="column is-12-mobile is-one-third-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd">
            <Bio accentColor={SiteConfig.themeColors.secondary} textColor="#ffffff"/>
          </div>
          <div className="column is-12-mobile is-one-third-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd">
            {
              catArray.map(cat => (
                <Link to={`/category/${_.kebabCase(cat.toString())}`}><span key={`${cat}`} className="tag has-background-warning-light has-text-grey-dark mr-1">{cat.toUpperCase()}</span></Link>
              ))
            }
            {
              tagArray.map(tag => (
                <Link to={`/tag/${_.kebabCase(tag.toString())}`}><span key={`${tag}`} className="tag has-text-white mr-1" style={{backgroundColor: SiteConfig.themeColors.accent}}>{tag.toUpperCase()}</span></Link>
              ))
            }
          </div>
          <div className="column is-12-mobile is-one-third-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd">
            <ul>
              <li>
                <Link to="/#home" className="ml-3">
                  <span className="icon-text">
                    <FaAngleDoubleRight className="icon has-text-white"/>
                    <span>Home</span>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/#about" className="ml-3">
                  <span className="icon-text">
                    <FaAngleDoubleRight className="icon has-text-white"/>
                    <span>About</span>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/#projects" className="ml-3">
                  <span className="icon-text">
                    <FaAngleDoubleRight className="icon has-text-white"/>
                    <span>Projects</span>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/#experience" className="ml-3">
                  <span className="icon-text">
                    <FaAngleDoubleRight className="icon has-text-white"/>
                    <span>Experience</span>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-4">
            <span>© {new Date().getFullYear()}, New Sprouts Media, LLC&nbsp;&nbsp;|&nbsp;&nbsp;Built with <a href="https://www.gatsbyjs.com">Gatsby</a> and <a href="https://bulma.io/">Bulma</a></span>
        </div>
      </div>
    </footer>

  )

}
